<template>
  <en-dialog :model-value="modelValue" width="80%" title="收款单" @close="$emit('update:model-value', false)">
    <template #operation>
      <button-ajax link :method="operation.export.click">导出</button-ajax>
    </template>

    <table-dynamic
      :height="600"
      code="RCVB"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="table.get"
      pagination
      :config="table.config"
    >
      <template #INVOICE_STATUS="{ row }: { row: EnocloudSettlementDefinitions['ReceivableQueryDto'] }">
        {{ row.invoiced === 'N' ? '未开票' : '已开票' }}
      </template>
    </table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      operation: {
        children: {
          export: {
            async click() {
              return this.ajax('GET /enocloud/settlement/receivable/export', this.table.$ajaxParams)
            }
          }
        }
      },
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/settlement/receivable/query',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        config: {
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          RECEIPT_COMMENT: { header: { filter: { type: 'text', field: 'receiptComment' } } },
          CELLPHONE: { header: { filter: { type: 'text', field: 'cellphone' } } },
          INVOICE_NO: { header: { filter: { type: 'text', field: 'invoiceNo' } } },
          INVOICE_TITLE: { header: { filter: { type: 'text', field: 'invoiceTitle' } } },
          INVOICE_DATE: {
            header: { filter: { type: 'date', field: ['invoiceDateStart', 'invoiceDateEnd'], props: { type: 'daterange' } } }
          },
          SERVICE_ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'advisorId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          INVOICE_STATUS: {
            header: {
              filter: {
                type: 'select',
                field: 'invoiced',
                props: {
                  options: [
                    { message: '已开票', code: 'Y' },
                    { message: '未开票', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'typeCode',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => {
                      params.paths = ['RVBTYPE']
                      params.payload = { excludes: ['POL'] }
                    }
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          INVOICE_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'invoiceTypeName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => (params.paths = ['INVCTP'])
                  },
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          INVOICE_STATUS: {
            header: {
              filter: {
                type: 'select',
                field: 'invoiced',
                props: {
                  options: [
                    { message: '已开票', code: 'Y' },
                    { message: '未开票', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SERVICE_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceCategoryId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/service/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          RECEIPT_DATETIME: {
            header: { filter: { type: 'date', field: ['businessStartDate', 'businessEndDate'], props: { type: 'daterange' } } }
          },
          PREPARED_BY: { header: { filter: { type: 'text', field: 'settlementByName' } } },
          CREDIT_BY: { header: { filter: { type: 'text', field: 'creditBy' } } },
          SERVICE_SETTLEMENT_DATETIME: {
            header: { filter: { type: 'date', field: ['settlementStartDate', 'settlementEndDate'], props: { type: 'daterange' } } }
          },
          PLATE_NO: { header: { filter: { type: 'text', field: 'plateNo' } } },
          RECEIPT_PAYMENT_METHOD: {
            header: {
              filter: {
                type: 'select',
                field: 'receiptPaymentMethod',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => (params.paths = ['PAMTMTD'])
                  },
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          DOCUMENT_COMMENT: { header: { filter: { type: 'text', field: 'comment' } } },
          SETTLED_DATETIME: {
            header: { filter: { type: 'date', field: ['settledStartDate', 'settledEndDate'], props: { type: 'daterange' } } }
          },
          INVOICE_IMAGE: {
            header: {
              filter: {
                type: 'select',
                field: 'invoiceImg',
                props: {
                  options: [
                    { message: '有', code: 'Y' },
                    { message: '无', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          RECEIPT_BYS: {
            header: {
              filter: {
                type: 'select',
                field: 'receiptBy',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: '' }
                }
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
